var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "pdf-sidebar",
      style: {
        background: _vm.$themeTokens.surface,
      },
    },
    [
      _c("nav", { style: { height: "100%" } }, [
        _vm.tabs.filter(function (t) {
          return !t.disabled
        }).length > 1
          ? _c(
              "div",
              { style: { display: "flex" } },
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "div",
                  {
                    key: tab.name,
                    ref: tab.name,
                    refInFor: true,
                    style: {
                      background:
                        _vm.selectedTab === tab.name
                          ? _vm.$themeTokens.annotation
                          : _vm.$themeTokens.transparent,
                      cursor: "pointer",
                      borderRadius: "2px",
                      opacity: tab.disabled ? 0.5 : 1,
                      pointerEvents: tab.disabled ? "none" : "auto",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        attrs: {
                          tabindex: tab.disabled ? -1 : 0,
                          "aria-label": tab.label,
                          role: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectTab(tab.name)
                          },
                          keydown: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.selectTab(tab.name)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "space",
                                  32,
                                  $event.key,
                                  [" ", "Spacebar"]
                                )
                              ) {
                                return null
                              }
                              return _vm.selectTab(tab.name)
                            },
                          ],
                        },
                      },
                      [
                        _c("KIcon", {
                          style: {
                            fill:
                              _vm.selectedTab === tab.name
                                ? _vm.$themeTokens.textInverted
                                : _vm.$themeTokens.text,
                            height: "24px",
                            width: "24px",
                          },
                          attrs: { icon: tab.icon },
                        }),
                        _c(
                          "KTooltip",
                          { attrs: { reference: tab.name, refs: _vm.$refs } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(tab.label) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "sidebar-content" },
          [
            _vm.selectedTab === "bookmarks"
              ? [
                  _c("Bookmarks", {
                    attrs: {
                      outline: _vm.outline,
                      goToDestination: _vm.goToDestination,
                      focusDestPage: _vm.focusDestPage,
                    },
                  }),
                ]
              : _vm._e(),
            _vm.selectedTab === "preview" ? [_c("span")] : _vm._e(),
            _vm.selectedTab === "annotations" ? [_c("span")] : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }