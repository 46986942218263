var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreFullscreen",
    {
      ref: "pdfRenderer",
      staticClass: "pdf-renderer",
      class: {
        "pdf-controls-open": _vm.showControls,
        "pdf-full-screen": _vm.isInFullscreen,
      },
      style: { backgroundColor: _vm.$themeTokens.text },
      on: {
        changeFullscreen: function ($event) {
          _vm.isInFullscreen = $event
        },
      },
    },
    [
      _vm.documentLoading || _vm.firstPageHeight === null
        ? _c("KLinearLoader", {
            staticClass: "progress-bar",
            attrs: {
              delay: false,
              type: _vm.progress > 0 ? "determinate" : "indeterminate",
              progress: _vm.progress * 100,
            },
          })
        : [
            _c("transition", { attrs: { name: "slide" } }, [
              _c(
                "div",
                {
                  staticClass: "fullscreen-header pdf-controls-container",
                  style: { backgroundColor: this.$themePalette.grey.v_100 },
                },
                [
                  _c(
                    "div",
                    [
                      _vm.outline && _vm.outline.length > 0
                        ? _c("KIconButton", {
                            staticClass: "controls",
                            attrs: {
                              ariaLabel: _vm.coreString("bookmarksLabel"),
                              tooltip: _vm.coreString("bookmarksLabel"),
                              "aria-controls": "sidebar-container",
                              icon: "menu",
                            },
                            on: { click: _vm.toggleSideBar },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("KIconButton", {
                        staticClass: "button-zoom-in controls",
                        attrs: {
                          ariaLabel: _vm.coreString("zoomIn"),
                          "aria-controls": "pdf-container",
                          icon: "add",
                        },
                        on: { click: _vm.zoomIn },
                      }),
                      _c("KIconButton", {
                        staticClass: "button-zoom-out controls",
                        attrs: {
                          ariaLabel: _vm.coreString("zoomOut"),
                          "aria-controls": "pdf-container",
                          icon: "remove",
                        },
                        on: { click: _vm.zoomOut },
                      }),
                      _c(
                        "KButton",
                        {
                          staticClass: "fullscreen-button",
                          attrs: {
                            primary: false,
                            appearance: "flat-button",
                            icon: _vm.isInFullscreen
                              ? "fullscreen_exit"
                              : "fullscreen",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.pdfRenderer.toggleFullscreen()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.fullscreenText) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c(
              "KGrid",
              { attrs: { gutter: "0" } },
              [
                _vm.showSideBar
                  ? _c(
                      "KGridItem",
                      {
                        staticClass: "sidebar-container",
                        class: { "mt-40": _vm.showControls },
                        attrs: { layout8: { span: 2 }, layout12: { span: 3 } },
                      },
                      [
                        _c("SideBar", {
                          attrs: {
                            outline: _vm.outline || [],
                            goToDestination: _vm.goToDestination,
                            focusDestPage: _vm.focusDestPage,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "KGridItem",
                  {
                    ref: "pdfContainer",
                    attrs: {
                      layout8: { span: _vm.showSideBar ? 6 : 8 },
                      layout12: { span: _vm.showSideBar ? 9 : 12 },
                    },
                  },
                  [
                    _c("RecycleList", {
                      ref: "recycleList",
                      staticClass: "pdf-container",
                      style: { height: _vm.elementHeight - 40 + "px" },
                      attrs: {
                        items: _vm.pdfPages,
                        itemHeight: _vm.itemHeight,
                        emitUpdate: true,
                        keyField: "index",
                      },
                      on: { update: _vm.handleUpdate },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("PdfPage", {
                                key: item.index,
                                attrs: {
                                  pageNum: item.index + 1,
                                  pdfPage: _vm.pdfPages[item.index].page,
                                  pageReady: _vm.pdfPages[item.index].resolved,
                                  firstPageHeight: _vm.firstPageHeight || 0,
                                  firstPageWidth: _vm.firstPageWidth || 0,
                                  scale: _vm.scale || 1,
                                  totalPages: _vm.pdfPages.length,
                                  eventBus: _vm.eventBus,
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }